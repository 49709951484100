<template>
  <div class="vh-100 d-flex align-items-center justify-content-center bg-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="card shadow-lg">
            <div class="card-body text-center p-5">
              <img :src="props.iconSrc" :alt="appName + ' Icon'" class="mb-4" style="width: 100px; height: 100px;">
              <h1 class="card-title mb-4">{{ props.appName }}</h1>

              <div v-if="!isSigningInProgress" class="mb-4">
                <button class="btn btn-primary btn-lg w-100 mb-3" @click="signIPA">
                  点击安装
                </button>
                <div class="row g-2">
                  <div class="col-6">
                    <a :href="contactCustomerServiceUrl" class="btn btn-outline-secondary w-100"
                      target="_blank">联系客服</a>
                  </div>
                  <div class="col-6">
                    <a :href="joinAfterSalesGroupUrl" class="btn btn-outline-secondary w-100" target="_blank">售后群聊</a>
                  </div>
                </div>
                <a :href="usageTutorialUrl" class="btn btn-outline-info w-100 mt-2" target="_blank">使用教程</a>
              </div>

              <div v-if="isSigningInProgress" class="progress-container mb-4">
                <svg class="progress-ring" width="150" height="150">
                  <circle class="progress-ring__circle" stroke="#e9ecef" stroke-width="8" fill="transparent" r="67"
                    cx="75" cy="75" />
                  <circle class="progress-ring__circle progress-ring__circle--progress" stroke="#007bff"
                    stroke-width="8" fill="transparent" r="67" cx="75" cy="75" :stroke-dasharray="circumference"
                    :stroke-dashoffset="dashOffset" />
                </svg>
                <div class="progress-text">{{ progressPercentage }}%</div>
              </div>

              <p v-if="isSigningInProgress" class="text-muted">
                正在进行签名，请稍候...
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import cookie from 'js-cookie'

const router = useRouter()
const store = useStore()
const udid = ref('')
const isSigningInProgress = ref(false)
const progressPercentage = ref(0)
const circumference = 2 * Math.PI * 67
const dashOffset = computed(() => {
  return circumference - (progressPercentage.value / 100) * circumference
})

// 新增的 URL
const contactCustomerServiceUrl = 'mqq://card/show_pslcard?src_type=internal&version=1&uin=33062920&card_type=person&source=sharecard'
const joinAfterSalesGroupUrl = 'https://qm.qq.com/q/jYHD9AjiJG'
const usageTutorialUrl = 'https://www.yuque.com/xianweiguanfangqijiandian/dd66cn/sl7mt2rolskuuwg2?singleDoc#'

/* eslint-disable no-undef */
const props = defineProps({
  appName: {
    type: String,
    required: true
  },
  iconSrc: {
    type: String,
    required: true
  },
  id: {
    type: String,
    required: true
  }
})

onMounted(() => {
  udid.value = store.state.udid ? store.state.udid : cookie.get('udid')
  if (!udid.value) {
    alert('未找到UDID，请重新获取。')
    router.push({ name: 'UDIDForm' })
  }
})

const simulateProgress = () => {
  let totalTime = 180000 // 总时间约80%的原时间，即1.6分钟
  let elapsedTime = 0
  let lastIncrease = Date.now()

  const interval = setInterval(() => {
    const now = Date.now()
    elapsedTime += now - lastIncrease
    lastIncrease = now

    if (elapsedTime >= totalTime || progressPercentage.value >= 99) {
      clearInterval(interval)
      return
    }

    // 计算当前应该达到的理论进度
    const theoreticalProgress = (elapsedTime / totalTime) * 100

    // 在理论进度的基础上添加一些随机性
    const randomFactor = Math.random() * 5 - 2.5 // -2.5 到 2.5 之间的随机数

    // 确保进度不会超过理论进度太多
    const maxProgress = Math.min(theoreticalProgress + 5, 99)

    // 计算新的进度，确保它不会小于当前进度
    const newProgress = Math.max(
      progressPercentage.value,
      Math.min(theoreticalProgress + randomFactor, maxProgress)
    )

    progressPercentage.value = Math.round(newProgress)

  }, 1000) // 每秒更新一次
}
const check_signing_status = async (udid, version) => {
  try {
    const response = await fetch(`/api/check_signing_status/${udid}/${version}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    if (data.success) {
      if (data.plist_url) {
        progressPercentage.value = 100;
        alert('签名成功，开始安装。');
        setTimeout(() => {
          window.location.href = `itms-services://?action=download-manifest&url=${encodeURIComponent(data.plist_url)}`
        }, 1000);
      } else {
        alert('签名成功，但未收到有效的安装链接。');
      }
    } else if (data.status === 'processing') {
      setTimeout(() => check_signing_status(udid, props.id), 5000);
    } else {
      alert('签名失败或未找到签名进程。');
      isSigningInProgress.value = false;
    }
  } catch (error) {
    console.error('Error:', error);
    alert('发生错误，请稍后再试。' + error);
    isSigningInProgress.value = false;
  }
};

const signIPA = async () => {
  try {
    isSigningInProgress.value = true;
    progressPercentage.value = 0;
    simulateProgress();

    const response = await fetch('/api/sign_ipa/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({ udid: udid.value, id: props.id })
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    if (data.success) {
      check_signing_status(udid.value, props.id);
    } else {
      alert(`签名失败：${data.error}`);
      isSigningInProgress.value = false;
    }
  } catch (error) {
    console.error('Error:', error);
    alert('发生错误，请稍后再试。' + error);
    isSigningInProgress.value = false;
  }
};
</script>


<style scoped>
.progress-container {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto;
}

.progress-ring__circle {
  transition: 0.35s stroke-dashoffset;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  font-weight: bold;
  color: #007bff;
}

.bg-light {
  background-color: #f8f9fa;
}

.card {
  max-width: 400px;
  margin: 0 auto;
}
</style>