<template>
  <router-view />
</template>

<script setup>
</script>

<style>
/* 你可以在这里添加全局样式 */
</style>
